import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { CURRENCY_SYMBOL, GET_TODAYS_ORDER } from '../../util/constants';
import { apiRequest } from '../../util/util';
import * as dateFns from 'date-fns';
import { getTimeFormat } from '../../util/util';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CircularProgress from '@material-ui/core/CircularProgress';
import Styles from './itemHistory.scss';
import '../../assets/css/custom.scss';


function Row(props) {

  const [showArrowIcon, setShowArrowIcon] = useState(false);

  const { row, index } = props;

  const [badgeStatus, setBadgeStatus] = useState("secondary");
 
  const [open, setOpen] = useState(false);

  useEffect(() => {

    if (!index) return;

    if (index === 1) {
      setOpen(true);
    }

  }, [index]);

  useEffect(() => {
    if (row.status === 'pending') {
      setBadgeStatus('secondary');
    } else if (row.status === 'processing') {
      setBadgeStatus('info');
    } else if (row.status === 'delivering') {
      setBadgeStatus('info');
    } else if (row.status === 'cancelled') {
      setBadgeStatus('danger');
    } else {
      setBadgeStatus('primary');
    }
  }, [row.status]);

  useEffect(() => {
    if (open) {
      setShowArrowIcon(true);
    } else {
      setShowArrowIcon(false);
    }
  }, [open]);

  const calculateItemTotal = (item, addons) => {

    const itemAddonsTotal = addons
      .filter(addon => addon.item_id === item.item_id)
      .reduce((acc, adn) => (acc + (adn.price * item.qty)), 0)
      ;

    const itemsTotal = (item.price * item.qty);

    return (itemsTotal + itemAddonsTotal).toFixed(2);
  };

  return (<>


    <TableRow component={Paper} onClick={() => setOpen(!open)} className="cursor">

      <TableCell component="th" scope="row"  >
        {`Ordered At: ${dateFns.format(new Date(row.created_at), getTimeFormat())}`}
        <br />
        {
          row.requested_time_is_asap &&
          `Requested For: ASAP`
        }
        {
          !row.requested_time_is_asap &&
          `Requested For: ${dateFns.format(new Date(row.requested_delivery_time), getTimeFormat())}`

        }
      </TableCell>

      <TableCell>
        Payment: {row.payment_type.toUpperCase()}
        <br />
        Total: {`${CURRENCY_SYMBOL}${row.total.toFixed(2)}`}
      </TableCell>

      <TableCell align="right">
        {
          !showArrowIcon &&
          <ExpandMoreIcon />
        }
        {
          showArrowIcon &&
          <ExpandLessIcon />
        }
      </TableCell>

    </TableRow>

    <TableRow>
      <TableCell colSpan={3}>
        <Collapse in={open} timeout="auto" unmountOnExit>

          <Typography variant="h6" gutterBottom>
            Order Details: {row.type.toUpperCase()}
          </Typography>


          <Table aria-label="purchases">
            <TableHead>
              <TableRow>
                <Table>
                  <TableRow>
                    <TableCell>Item Name</TableCell>
                    <TableCell align="right">Item Price</TableCell>
                    <TableCell align="right">Total price</TableCell>
                  </TableRow>
                </Table>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.items.map((item) => {
                return <>

                  <TableRow key={item.item_id}  >
                    <Table>
                      <TableRow>
                        <TableCell className="itemName">
                          {`${item.qty} ${item.name}`}
                        </TableCell>

                        <TableCell align="right" className="itemPrice">
                          {CURRENCY_SYMBOL}{item.price.toFixed(2)}
                        </TableCell>

                        <TableCell align="right" rowSpan={row.addons.length + 1} className="itemPrice">
                          {CURRENCY_SYMBOL}
                          {calculateItemTotal(item, row.addons)}
                        </TableCell>
                      </TableRow>

                      {
                        row.addons.filter(addon => item.item_id == addon.item_id).map(addon => (


                          <TableRow key={`${addon.item_id}_${addon.addon_id}`}>
                            <TableCell scope="row" className="addonName">
                              {addon.name}
                            </TableCell>

                            <TableCell align="right" className="addonPrice">
                              {CURRENCY_SYMBOL}{addon.price.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))
                      }

                    </Table>
                  </TableRow>


                </>

              })}

            </TableBody>

          </Table>


          <TableContainer >
            <Table>
              <TableBody>

                {
                  row.type === "delivery" &&
                  <TableRow style={{ marginTop: 5 }}>
                    <TableCell colSpan={8} align="right" className="otherText">Delivery Charge</TableCell>
                    <TableCell align="right" className="itemPrice" >{`+ ${CURRENCY_SYMBOL}${row.delivery_amount.toFixed(2)}`}</TableCell>
                  </TableRow>

                }


                <TableRow>
                  <TableCell colSpan={8} align="right" className="otherText">Discount</TableCell>
                  <TableCell align="right" className="itemPrice">{`- ${CURRENCY_SYMBOL}${row.discount_amount.toFixed(2)}`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={8} align="right" className="otherText">Coupon</TableCell>
                  <TableCell align="right" className="itemPrice">{`- ${CURRENCY_SYMBOL}${row.coupon_amount.toFixed(2)}`}</TableCell>
                </TableRow>

                {
                  row.points_used !== 0 &&
                  <TableRow>
                    <TableCell colSpan={8} align="right" className="otherText">Points ({row.points_used})</TableCell>
                    <TableCell align="right" className="itemPrice">{`- ${CURRENCY_SYMBOL}${row.points_amount.toFixed(2)}`}</TableCell>
                  </TableRow>

                }

                {
                  row.adjustment_amount > 0 &&
                  <TableRow>
                    <TableCell colSpan={8} align="right" className="otherText">Adjust amount</TableCell>
                    <TableCell align="right" className="itemPrice">{`+${CURRENCY_SYMBOL}${row.adjustment_amount.toFixed(2)}`}</TableCell>
                  </TableRow>
                }


                <TableRow>
                  <TableCell colSpan={8} align="right" className="otherText">Total Cost</TableCell>
                  <TableCell align="right" className="itemPrice">{`${CURRENCY_SYMBOL}${row.total.toFixed(2)}`}</TableCell>
                </TableRow>


              </TableBody>
            </Table>
          </TableContainer>

          {
            row.type === "collection" &&
            <Box margin={1}>

              <Typography variant="h6" gutterBottom>
                Collection Details
              </Typography>


              <TableContainer component={Paper}>
                <Table>
                  <TableBody>

                    <TableRow >
                      <TableCell colSpan={1}>Collection Status</TableCell>
                      {
                        row.status !== 'delivered' &&
                        <TableCell align="right"><span className={`badge badge-${badgeStatus} order-status `}>{row.status}</span></TableCell>

                      }
                      {
                        row.status === 'delivered' &&
                        <TableCell align="right"><span className={`badge badge-${badgeStatus} order-status `}>Collected</span></TableCell>

                      }
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          }



          {
            row.type === "delivery" &&
            <Box margin={1}>

              <Typography variant="h6" gutterBottom>
                Delivery Details
              </Typography>


              <TableContainer component={Paper}>
                <Table>
                  <TableBody>

                    <TableRow >
                      <TableCell colSpan={1}>Delivery Status</TableCell>

                      <TableCell align="right"><span className={`badge badge-${badgeStatus} order-status`}>{row.status}</span></TableCell>
                    </TableRow>

                    <TableRow >
                      <TableCell colSpan={1}>House No</TableCell>

                      <TableCell align="right">{row.house_no}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={1}>Street</TableCell>

                      <TableCell align="right">{row.street_name}</TableCell>

                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={1}>City</TableCell>

                      <TableCell align="right">{row.city}</TableCell>

                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={1}>Postcode</TableCell>

                      <TableCell align="right">{row.postcode}</TableCell>

                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={1}>Delivery Instruction</TableCell>

                      <TableCell align="right">{row.note}</TableCell>

                    </TableRow>


                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          }
        </Collapse>
      </TableCell>
    </TableRow>
  </>


  );
}



function ItemHistory() {

  const [isLoading, setIsLoading] = useState(true);

  const [loadMessage, setLoadMessage] = useState("Please Wait");

  const [orderHistoryData, setOrderHistoryData] = useState([]);

  useEffect(() => {

    async function getTodayOrders() {

      try {
    const responseData = await apiRequest.get(GET_TODAYS_ORDER);
    setOrderHistoryData(responseData.data);
    if (responseData.data.data.length) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setLoadMessage("You have no order today.");
    }
      } catch (e) {
        setLoadMessage("You have no order today.");
      }
    }

    getTodayOrders();

  }, [setOrderHistoryData]);


  return (<>

    <TableContainer component={Paper} className="tableHelmet" style={{ textAlign: "center" }}>
      <Table aria-label="collapsible table">

        {
          isLoading &&
          <CircularProgress />
        }
        {
          orderHistoryData.data?.length != 0 &&
          <TableBody>
            {orderHistoryData.data?.map((row, idx) => (

              <Row key={idx} row={row} index={idx + 1} />
            ))}
          </TableBody>
        }
        {
          !orderHistoryData.data?.length &&
          <h3 className="profile-padding">
            {loadMessage}
          </h3>

        }

      </Table>
    </TableContainer>
  </>);
}


export default ItemHistory;


