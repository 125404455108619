import React, { Component, useRef } from 'react'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Box, Button, Typography } from '@material-ui/core';
import PageHeader from '../../../components/pageHeader/pageHeader';
import PageLoader from '../../../components/util/PageLoader';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { clearCart } from '../../../store/cart/cartActions';
import routes from "../../../util/routes";
import { urlRedirectSet } from '../../../store/url/urlActions';
import { grey } from '@material-ui/core/colors';
import Particles from 'react-tsparticles';
import './orderSuccess.scss';
import SlideAnimation from '../../../components/util/Animations/SlideAnimation';


function OrderSuccess({ ...otherProps }) {

    useEffect(() => {
        //clear all cart items
        otherProps.clearCart();
    const redirectTimeout = setTimeout(() => {
      redirectToHistory()
    }, 5000);

        return () => {
           
            clearTimeout(redirectTimeout);
        };

    }, []);

  useEffect(() => {

    if (!otherProps.user.isLoggedIn) {

      otherProps.setUrlRedirect(otherProps.location.pathname, routes.login);

      // redirect
      otherProps.history.push(routes.login);

      return;
    }
  }, [otherProps.user]);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            textAlign: 'center',
        },
        header: {

            textAlign: 'center',
        },

        successColor: {
            color: 'green'
        },

        iconSize: {
            fontSize: 70,
        },

        text25: {
            fontSize: 50,
        },

        welcomeText: {
      color: grey['50'],
            fontSize: 35,
            marginTop: 20
        },

        historyButton: {
            fontSize: 20,
            marginTop: 20
        }

    }));

    const redirectToHistory = () => {
        otherProps.history.push(routes.orderHistory);
    }

    const classes = useStyles();

    return (<>

    <SlideAnimation in={true}>

        <PageLoader show={false} />
        <PageHeader pageName="Order Success" />
        <Card className={`${classes.root} ${classes.successColor}`}>

        <CardContent style={{
          position: 'relative',
          padding: 0
        }}>

          <Particles
            canvasClassName="fireworks-particle-canvas"
            options={{
              fullScreen: {
                enable: true
              },
              detectRetina: true,
              background: {
                color: "#000"
              },
              fpsLimit: 60,
              emitters: {
                direction: "top",
                life: {
                  count: 0,
                  duration: 0.1,
                  delay: 0.1
                },
                rate: {
                  delay: 0.15,
                  quantity: 1
                },
                size: {
                  width: 100,
                  height: 0
                },
                position: {
                  y: 100,
                  x: 50
                }
              },
              particles: {
                number: {
                  value: 0
                },
                destroy: {
                  mode: "split",
                  split: {
                    count: 1,
                    factor: { value: 1 / 3 },
                    rate: {
                      value: 100
                    },
                    particles: {
                      stroke: {
                        color: {
                          value: ["#ffffff", "#b22234", "#b22234", "#3c3bfe", "#3c3bfe", "#3c3bfe"]
                        },
                        width: 1
                      },
                      number: {
                        value: 0
                      },
                      collisions: {
                        enable: false
                      },
                      opacity: {
                        value: 1,
                        animation: {
                          enable: true,
                          speed: 0.7,
                          minimumValue: 0.1,
                          sync: false,
                          startValue: "max",
                          destroy: "min"
                        }
                      },
                      shape: {
                        type: "circle"
                      },
                      size: {
                        value: 1,
                        animation: {
                          enable: false
                        }
                      },
                      life: {
                        count: 1,
                        duration: {
                          value: {
                            min: 1,
                            max: 2
                          }
                        }
                      },
                      move: {
                        enable: true,
                        gravity: {
                          enable: false
                        },
                        speed: 2,
                        direction: "none",
                        random: true,
                        straight: false,
                        outMode: "destroy"
                      }
                    }
                  }
                },
                life: {
                  count: 1
                },
                shape: {
                  type: "line"
                },
                size: {
                  // value: 50,
                  value: { min: 1, max: 50 },
                  animation: {
                    enable: true,
                    sync: true,
                    speed: 150,
                    startValue: "max",
                    destroy: "min"
                  }
                },
                stroke: {
                  color: {
                    value: "#ffffff"
                  },
                  width: 1
                },
                rotate: {
                  path: true
                },
                move: {
                  enable: true,
                  gravity: {
                    acceleration: 15,
                    enable: true,
                    inverse: true,
                    maxSpeed: 100
                  },
                  speed: { min: 10, max: 20 },
                  outModes: {
                    default: "destroy",
                    top: "none"
                  },
                  trail: {
                    fillColor: "rgba(20,20,20,.5)",
                    enable: true,
                    length: 10
                  }
                }
              }
            }}
            />

          <Typography
            variant="h4"
            component={Box}
            style={{
              paddingTop: 50,
              position: 'relative',
            }}
          >Order Successful
          </Typography>

          <Box position="relative" paddingBottom={3} paddingTop={3}>
                    <CheckCircleOutlinedIcon className={classes.iconSize} />
                </Box>
          <Box position="relative" paddingBottom={3}>
                    <h3 className={classes.welcomeText}>Thank You for Your Order.</h3>
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.historyButton}
                    onClick={redirectToHistory}
            style={{
              marginBottom: 50
            }}
                >
                    Order History
                </Button>

            </CardContent>
        </Card>

    </SlideAnimation>
    </>);
}

const mapStateToProps = state => ({
  user: state.user,
  cart: state.cart,
});

const mapDispatchToProps = dispatch => ({
   clearCart: () => dispatch(clearCart()),
  setUrlRedirect: (from, to) => dispatch(urlRedirectSet(from, to)),

})

export default connect(mapStateToProps, mapDispatchToProps)(OrderSuccess);


