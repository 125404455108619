import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Card, CardContent, Menu, MenuItem, FormControlLabel, Switch, Box, Typography} from '@material-ui/core';
import { useStyles } from './address.style';
import { EditSharp, Delete, MoreVert } from '@material-ui/icons';
import { removeAddress } from '../../store/user/userActions'
import { USER_ADDRESS } from '../../util/constants';

function AddressCard({
  address,
  isSelected = false,
  selectAddressHandler,
  clearSelectHandler,
  showEditHandler,
  ...otherProps
}) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const openHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = () => {
    setAnchorEl(null);
  };

  const deleteHandler = async (id) => {
    const deleteUrl = `${USER_ADDRESS}/${id}`;

    const deletedAddress = await otherProps.removeAddress(deleteUrl);

    if (deletedAddress) {
      clearSelectHandler();
    }
  };

  const editHandler = (address) => {
    selectAddressHandler(address);
    showEditHandler(true);
    closeHandler();
  };

  return (
    <Card className={[classes.root, isSelected && classes.selectedAddressBorder].join(' ')}>
      <CardContent>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.addressCardHeader}
          mb={1}
        >
          {/*<div className={[classes.display, classes.marginBottom20, classes.addressHeader].join(' ')}>*/}
            <Box className={`${classes.header} ${classes.itemColor}`}>{address.type}</Box>

            {/*menu*/}
            <Box>
              <MoreVert className={[classes.menuIconSize, classes.cursorHover].join(' ')} color="primary" onClick={openHandler} />

              <Menu
                id={address.id}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeHandler}
                key={address.id}
              >
                <MenuItem>
                  <Delete id={`${address.id}d`} color="secondary" onClick={() => deleteHandler(address.id)} />
                </MenuItem>
                <MenuItem>
                  <EditSharp id={`${address.id}e`} color="primary" onClick={() => editHandler(address)} />
                </MenuItem>
              </Menu>
            </Box>
          {/*</div>*/}
        </Box>


        <Box className={classes.addressItem}>
          <div>{`House No: `}</div>
          <div>{`${address.house_no}`}</div>
        </Box>

        <Box className={classes.addressItem}>
          <div>{`Street: `}</div>
          <div>{`${address.street_name}`}</div>
        </Box>

        <Box className={classes.addressItem}>
          <div>{`City: `}</div>
          <div>{`${address.city}`}</div>
        </Box>

        <Box className={classes.addressItem}>
          <div>{`County: `}</div>
          <div>{`${address.county}`}</div>
        </Box>

        <Box className={classes.addressItem}>
          <div>{`Postcode: `}</div>
          <div>{`${address.postcode}`}</div>
        </Box>

        <Box className={classes.addressItem}>
          <div>{`Address Note: `}</div>
          <div>{address.note}</div>
        </Box>

        <Box className={classes.addressItem} mb={0} pb={0}>
          <Typography variant="h5">Select</Typography>
          <FormControlLabel
            className={classes.switchLabel}
            control={
              <Switch
                color="primary"
                checked={isSelected}
                onChange={() => selectAddressHandler(address)}
              />
            }
           label=""
          />
        </Box>

      </CardContent>
    </Card>
  );
}


AddressCard.propTypes = {
  address: PropTypes.object,
  isSelected: PropTypes.bool.isRequired,
  selectAddressHandler: PropTypes.func,
  clearSelectHandler: PropTypes.func,
  showEditHandler: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  removeAddress: (url) => dispatch(removeAddress(url))
});

export default connect(null, mapDispatchToProps)(AddressCard);
