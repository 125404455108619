import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  modalCard: {
    maxWidth: '90vw',
    minWidth: '200px',
    minHeight: '200px',
    overflow: 'auto',
    maxHeight: '100vh'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  modalTitle: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fontSize: '25px',
    borderBottom: '1px solid',
    borderColor: theme.palette.red900,
    display: 'flex',
    justifyContent: 'space-between'
  },
  modalTitleCloseButton: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1)
  },
  modalBody: {
    padding: theme.spacing(1),
  },
  modalFooter: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  modalFooterCancelButton: {
    marginRight: 10
  }
}));

export default useStyles;
