import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import FadeAnimation from '../Animations/FadeAnimation';
import { Box, Button, Grid } from '@material-ui/core';
import useStyles from './ModalComp.style';
import { Close } from '@material-ui/icons';


function ModalComp({
  backDropTimeout= 500,
  submitBtnText = 'Submit',
  open,
  closeHandler,
  closeOnBackdropClick=true,
  submitHandler,
  showSubmitLoader = true,
  clickNode,
  title,
  hideFooter = false,
  disableFocusEnforcement = false,
  ...otherProps
}) {
  
  const classes = useStyles();
  const [show, setShow] = useState(false);
  useEffect(()=> {
    if (open !== undefined) {
      setShow(open);
    }
  }, [open]);
  
  const handleOpen = () => {
    setShow(true);
  };
  
  const handleClose = () => {
    setShow(false);
  
    if (typeof closeHandler === 'function') {
      closeHandler(false);
    }
  };
  
  return (
    <div>
      {
        clickNode &&
        <span onClick={handleOpen}>
          { clickNode }
        </span>
      }
      
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal}
        open={show}
        onClose={handleClose}
        closeAfterTransition
        disableEnforceFocus={disableFocusEnforcement}
        BackdropComponent={Backdrop}
        disableBackdropClick={!closeOnBackdropClick}
        BackdropProps={{
          timeout: backDropTimeout,
        }}
      >
        <FadeAnimation in={show}>
          <Box className={[classes.paper, classes.modalCard].join(' ')}>
            <Box className={classes.modalTitle}>
              <Box>{ title }</Box>
              <Box className={classes.modalTitleCloseButton} onClick={handleClose}>
                <Close />
              </Box>
            </Box>
            
            <Box className={classes.modalContent}>
              <Box className={classes.modalBody}>
                {
                  otherProps.children || <h1>Default Modal Content</h1>
                }
              </Box>
              {
                !hideFooter &&
                <Box className={classes.modalFooter}>
                  <Button
                    className={classes.modalFooterCancelButton}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                   <Button color="secondary" variant="contained" onClick={submitHandler}>{submitBtnText}</Button>
                </Box>
              }
            </Box>
          </Box>
        </FadeAnimation>
      </Modal>
    </div>
  );
}

ModalComp.propTypes = {
  open: PropTypes.bool,
  backDropTimeout: PropTypes.number,
  submitBtnText: PropTypes.string,
  submitHandler: PropTypes.func,
  showSubmitLoader: PropTypes.bool,
  closeHandler: PropTypes.func,
  closeOnBackdropClick: PropTypes.bool,
  clickNode: PropTypes.node,
  title: PropTypes.string,
  hideFooter: PropTypes.bool,
};

export default ModalComp;
