import { Grid, List, ListItem, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import PageHeader from '../../../components/pageHeader/pageHeader';
import ItemHistory from '../../../components/order/itemsHistory';
import { Link } from 'react-router-dom';
import routes from "../../../util/routes";
import '../../../assets/css/custom.scss';
import { connect } from 'react-redux';
import { urlRedirectSet } from '../../../store/url/urlActions';
import SlideAnimation from '../../../components/util/Animations/SlideAnimation';

const useStyles = makeStyles((theme) => ({

  activeColor: {
    backgroundColor: "#F3F3F3",
  },
}));

function OrderHistoryPage({ ...otherProps }) {

  //checking if user login or not
  useEffect(() => {

    if (!otherProps.user.isLoggedIn) {

      otherProps.setUrlRedirect(otherProps.location.pathname, routes.login);

      // redirect
      otherProps.history.push(routes.login);

      return;
    }
  });

  const classes = useStyles();

  return (<>

    <SlideAnimation in ={true}>

    <PageHeader pageName="Order History" />

    <Grid container>

      <Grid item xs={12} md={3} margin="2" >
        <Link to={routes.orderHistory}>
          <Paper className={classes.activeColor}>
              <List component="nav" align="center" className="profile-padding profile-margin">
              Today's Order
            </List>
          </Paper>
        </Link>

        <Link to={routes.userProfile}>
          <Paper>
              <List component="nav" align="center" className="profile-padding profile-margin">
              My Profile
          </List>
          </Paper>
        </Link>

      </Grid>

      <Grid item xs={12} md={9}>
        <ItemHistory />
      </Grid>

    </Grid>
    </SlideAnimation>
  </>);
}

const mapStateToProps = state => ({
  user: state.user,
  cart: state.cart,
});

const mapDispatchToProps = dispatch => ({
  setUrlRedirect: (from, to) => dispatch(urlRedirectSet(from, to)),

});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryPage);
