import { Avatar, Box, Button, Paper, Typography } from '@material-ui/core';
import UserAvatar from '../UserAvatar';
import { apiRequest } from '../../../util/util';
import { PRE_AUTH_ENDPOINT } from '../../../util/constants';
import { updateRequireAuth } from '../../../store/user/userActions';
import { connect } from 'react-redux';

function SessionExpire({ ...otherProps }) {

  const UpdateAuthentication = async () => {
    await apiRequest.get(PRE_AUTH_ENDPOINT);
    otherProps.updateRequireAuth(false);
  }

  return (
    <div style={{padding: 15}}>

      <Box align="center">
        <Avatar>
          <UserAvatar />
        </Avatar>
      </Box>
      <Box align="center" style={{marginBottom: 10}}>
        <Paper square elevation={0} >
          <Typography variant="h5">
            {otherProps.user.name}
          </Typography>
        </Paper>
      </Box>

      <Box style={{marginBottom: 3}}>

        <Paper square elevation={0} >
          <Typography variant="h5"> Your session has expired <br /> due to inactivity</Typography>
        </Paper>

      </Box>

      <Box align="center">

        <Button
          type="button"
          className="point-link"
          variant="contained"
          color="primary"
          onClick={UpdateAuthentication}

        >
          Continue Again
        </Button>
      </Box>

    </div>
  );
}


const mapStateToProps = state => ({
  user: state.user.data,
});


const mapDispatchToProps = dispatch => ({
  updateRequireAuth: (payload) => dispatch(updateRequireAuth(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpire);