import React from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import ThemeImage from '../../components/util/ThemeImage';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReservationComponent from '../../components/reservation/reservation';
import useSetting from '../../hooks/useSetting';



function ReservationPage() {

  const setting = useSetting([
    'site_title',
  ]);

  return (<>

    <PageHeader pageName="Reservation" bgClass="breadcrumb-bg-services" />

    {/* features-4 */}

    <ReservationComponent />

    <section id="services" className="w3l-stats services py-5">
      <div className="container py-lg-5 py-md-4">
        <h3 className="title-big text-center">Make your reservation with {setting.site_title}</h3>
        <div className="row text-center img-grids pt-2">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="ser-bg1">
              <div className="p-md-5 p-3">
                <p className="reservation-text">
                  Booking a table at your favorite Indian restaurant for yourself, friends, or any event has never been easier than with our online reservation system. We've created the most user-friendly restaurant reservation system on the market, allowing you to book appointments online and enjoy outstanding service.
                </p>

                <h4 className="text-left mb-3 mt-3">We built an easy online reservation system for you and your guests:

                </h4>
                <p className="reservation-text">
                  One of the most essential aspects of our restaurant is that we offer "simple reservations," which allows our staff to keep track of all reservations and determine where clients should be seated. Employees and clients do not need to spend a lot of time learning how to use our online reservation system. We used a simple and straightforward configuration to make the system easy to use.
                </p>

                <h4 className="text-left mb-3 mt-3">We keep note of how we can improve our service for your better experience:

                </h4>
                <p className="reservation-text">
                  We've made managing your online reservations and walk-ins a breeze. The monthly calendar overview enables keeping track of the number of reservations and visitors on each day of the month easier. Furthermore, we've made it easy for you to communicate with us both before and after your visit. In this way, we are committed to avoiding misunderstandings.
                </p>

                <p className="reservation-text">
                  After all, we made our booking process exceedingly straightforward and visitor-friendly to avoid any form of client discontent. Simply complete the following form with the necessary information and let us to assist you.
                </p>

                <h4 className="text-left mt-3">
                  Note: {setting.site_title} is committed to protecting and maintaining the privacy of its customers' personal information.
                </h4>

              </div>
            </div>
          </div>


          {/* <div className="col-lg-4 col-md-6 col-sm-12 mt-md-0 mt-4">
            <div className="ser-bg2">
              <div className="p-md-5 p-3">
                <h6>We built an easy online reservation system for you and your guests:</h6>
                <p>
                  One of the most essential aspects of our restaurant is that we offer "simple reservations," which allows our staff to keep track of all reservations and determine where clients should be seated. Employees and clients do not need to spend a lot of time learning how to use our online reservation system. We used a simple and straightforward configuration to make the system easy to use.
                </p>


              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-lg-0 mt-4">
            <div className="ser-bg3">
              <div className="p-md-5 p-3">
                <h4>We keep note of how we can improve our service for your better experience:</h4>
                <p>
                  We've made managing your online reservations and walk-ins a breeze. The monthly calendar overview enables keeping track of the number of reservations and visitors on each day of the month easier. Furthermore, we've made it easy for you to communicate with us both before and after your visit. In this way, we are committed to avoiding misunderstandings.
                </p>
              </div>
            </div>
          </div> */}


        </div>
      </div>
    </section>



  </>);
}

const mapStateToProps = state => ({
  user: state.user,
  date: state.cart.delivery.date,
  time: state.cart.delivery.time,
  opening: state.opening.data,
});

export default connect(mapStateToProps)(ReservationPage);
