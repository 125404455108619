// define custom
const validationMessages = {
  required: field => `${field} is required`,
  min: (field, validation, args) => {
    return `${field} cannot be less than ${args[0]}`;
  },
  max: (field, validation, args) => {
    return `${field} cannot be more than ${args[0]}`;
  },
  confirmed: (field) => {
    return `${field} confirmation does not matched`;
  },
  email: field => `${field} must be a valid email address`,
  number: field => `${field} contains only numbers`,

  // custom rules
  matchWithField: (field, validation, args) => {
    return `${field} must match with ${args[0]}`;
  },
  containsNumber: (field, validation, args) =>{
    return `${field} must contains ${args[0]} number`;
  }
};

export default validationMessages;
