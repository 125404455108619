import { useState, useEffect } from 'react';
import PaymentMethods from '../cards/paymentMethods';
import CardModal from '../cards/cardModal';
import StripeModal from '../cards/stripeModal';
import { cartPaymentMethodUpdate } from "../../store/cart/cartActions";
import { connect } from "react-redux";
import useSetting from '../../hooks/useSetting';
import { Card, Grid } from '@material-ui/core';
import { useStyles } from '../cards/cardPayment.style';



function CardPayment({ ...otherProps }) {

  const classes = useStyles();

  const [selectCard, setSelectCard] = useState('cash');

  const [showModal, setShowModal] = useState(false);

  const paymentSetting = useSetting([
    'payment_stripe'
  ]);

  useEffect(() => {
    if (otherProps.paymentMethod !== 'points' ){
      return;
    }
    setSelectCard('points');
  }, [otherProps.paymentMethod]);


  useEffect(() => {
    setShowModal((selectCard === 'card'));

    otherProps.updatePaymentMethod(selectCard)
  }, [selectCard])

  const resetPaymentMethod = () => {
    setSelectCard('cash');
    setShowModal(false);
  };


  return (<>
    {
      selectCard !== 'points' &&
    <PaymentMethods
      cartSelection={selectCard}
      cartSelectionHandler={setSelectCard}
    />
    }

    {
      selectCard === 'points' &&
      <Grid container style={{ marginTop: 20 }}>
        <Card className={[classes.root].join(' ')} style={{ width: "100vw" }}>
          You can complete this order with points only, no payment required.

        </Card>
      </Grid>
    }


    {
      !paymentSetting.payment_stripe &&
    <CardModal
      show={showModal}
      selectedPaymentMethod={selectCard}
      resetPaymentMethodHandler={resetPaymentMethod}
    />
    }

    {
      paymentSetting.payment_stripe &&
      <StripeModal
        show={showModal}
        selectedPaymentMethod={selectCard}
        resetPaymentMethodHandler={resetPaymentMethod}
      />
    }

  </>);
}

const mapStateToProps = state => ({
  paymentMethod: state.cart.payment.method,
  adjustAmount: state.cart.adjustPointMoney,
});

const mapDispatchToProps = dispatch => ({
  updatePaymentMethod: (method) => dispatch(cartPaymentMethodUpdate(method))
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPayment);
