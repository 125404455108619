import React, { Component } from 'react';
import PageHeader from '../../../components/pageHeader/pageHeader';
import { Button, Grid, Hidden, Paper } from '@material-ui/core';
import SideMenu from '../../../components/menu/sideMenu';
import Cart from '../cart/cart';
import axios from 'axios';
import { API_BASE_URL } from '../../../util/constants';
import CategoryMenu from '../../../components/menu/CategoryMenu';
import PageLoader from '../../../components/util/PageLoader';
import { connect } from 'react-redux';
import { urlRedirectSet } from '../../../store/url/urlActions';
import { MenuAdded, SelectedMenuAdded } from '../../../store/menu/menuActions';
import Offers from "../../../components/offer/Offers";
import { isRestaurantClosed } from "../../../util/util";
import { cartOrderTypeUpdate } from '../../../store/cart/cartActions';
import { updateUnavailableOrderType } from '../../../store/opening/openingActions';
import SlideAnimation from '../../../components/util/Animations/SlideAnimation';

class MenuPage extends Component {

  state = {
    itemsLoading: true,
    isClosedCalculated: false,
    isNotAvailableOrderTypeChecked: false,
  };


  componentDidMount() {

    if (!this.props.items.length) {
      this.fetchItems();
    }

  }

  componentDidUpdate() {

    if (!this.state.isNotAvailableOrderTypeChecked) {
      this.checkNotAvailableOrderType();
    }

    const hourLength = this.props.openingHour?.length;

    if (hourLength && !this.state.isClosedCalculated) {

      const lastElement = this.props.openingHour[hourLength - 1];

      isRestaurantClosed(lastElement);

      this.setState({
        isClosedCalculated: true,
      })
    }

  }

  async checkNotAvailableOrderType() {
    try {


      const data = await this.props.openingHour;

      const totalArrayLen = data.length;

      const hasNoDeliveryArray = data.filter(val => val.enabled_delivery === false);

      const hasNoCollectionArray = data.filter(val => val.enabled_collection === false);

      if (hasNoCollectionArray.length === totalArrayLen
        || hasNoDeliveryArray.length === totalArrayLen) {

        if (hasNoDeliveryArray.length) {

          //update default ordertype in store if delivery not available
          this.props.cartOrderTypeUpdate("Collection");

          //update unavailable order type in store
          this.props.updateUnavailableOrderType("Delivery");

        } else {
          this.props.updateUnavailableOrderType("Collection");
        }
      }
      this.setState({
        isNotAvailableOrderTypeChecked: true,
      })
    } catch (e) {

    }
  }

  async fetchItems() {
    try {

      if (!this.props.categories.length) {
        const response = await axios.get(`${API_BASE_URL}/menu`);

        const items = response.data.items;

        const categories = response.data.categories;

        this.props.MenuAdded(categories, items);

        if (categories.length) {
          const firstCategory = categories[0];
          this.props.SelectedMenuAdded(firstCategory, this.props.items[firstCategory.name]);
        }

      }

      const newState = {
        itemsLoading: false
      };


      this.setState(() => newState);

    } catch (e) {
      console.log(new Error(e.message));
    }
  }



  render() {

    return (<>

      <SlideAnimation in={true}>

      <PageLoader show={this.state.itemsLoading} />

      <PageHeader
        pageName="Order Online"
        breadCrumbComponentPadding="10px 0"
        breadCrumbComponent={
          <Offers>
            {
              (openOffers) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openOffers}
                >
                  Available Offers
                </Button>
              )
            }
          </Offers>
        }
      />

      <Grid container>

        <Hidden smDown>
            <Grid item xs={12} md={3} style={{ paddingLeft: 7 }}>
            <Paper>
              <SideMenu />
            </Paper>
          </Grid>
        </Hidden>



        <Grid item xs={12} md={6} >
          {
            this.props.selectedItemsFromState[0] &&
            <CategoryMenu />
          }
        </Grid>

        <Hidden smDown>
          <Grid item xs={12} md={3}>
            <Cart />
          </Grid>
        </Hidden>

      </Grid>

      </SlideAnimation>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.user,
  categories: state.menu.categories,
  items: state.menu.items,
  selectedCategoryFromState: state.menu.selectedCategory,
  selectedItemsFromState: state.menu.selectedItems,
  openingHour: state.opening.todaysTiming.hours,
});
const mapDispatchToProps = dispatch => ({
  setUrlRedirect: (from, to) => dispatch(urlRedirectSet(from, to)),
  MenuAdded: (categories, items) => dispatch(MenuAdded(categories, items)),
  cartOrderTypeUpdate: (type) => dispatch(cartOrderTypeUpdate(type)),
  updateUnavailableOrderType: (type) => dispatch(updateUnavailableOrderType(type)),
  SelectedMenuAdded: (selectedCategory, selectedItems) => dispatch(SelectedMenuAdded(selectedCategory, selectedItems)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage);
