import { Grid } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";

export default function CardPaymentAddressStep({address, changeHandler, ...otherProps}) {

  return (
    <Grid container>
      <Grid item xs={12}>
        <form noValidate>
          <TextField
            type="text"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="street"
            label="House No., Street Name"
            autoComplete='off'
            value={address.street}
            onChange={changeHandler}
          />
          <TextField
            type="text"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="city"
            label="City"
            autoComplete='off'
            value={address.city}
            onChange={changeHandler}
          />
          <TextField
            type="text"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="postcode"
            label="Postcode"
            autoComplete='off'
            value={address.postcode}
            onChange={changeHandler}
          />
          <TextField
            type="text"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="county"
            label="County"
            autoComplete='off'
            value={address.county}
            onChange={changeHandler}
          />
        </form>
      </Grid>
    </Grid>
  );
}
