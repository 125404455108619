import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Box, Hidden, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import BottomNav from './BottomNav';
import useSetting from "../../hooks/useSetting";
import { SETTING_FILE_PATH } from '../../util/constants';
import routes from '../../util/routes';
import Copyright from '../../components/util/copyright';
import { getTimeFormatFromTimeString } from '../../util/util';

import './footer.scss';


function Footer({ ...otherProps }) {

  const setting = useSetting(['site_title',
    'facebook_url',
    'instagram_url',
    'trip_advisor',
    'address',
    'country_code',
    'phone',
    'display_email',
    'designed_by',
    'copyright_text',
    'site_logo_large',
    'about_description',

  ]);

  const footerTiming = useMemo(() => {
    return (<>
      {
        otherProps.opening.map(day => (
          <Box key={day.id}>
            <Typography
              variant="body1"
              className={`footer-time`}
              style={{
                fontSize: 18,
                fontWeight: 600
              }}
            >
              {day.day}
            </Typography>
            {
              day.hours.map(hour => (
                <Typography
                  kay={`hr${hour.id}`}
                  variant="body1"
                  className="footer-time"
                  style={{
                    marginLeft: 30
                  }}
                >
                  {getTimeFormatFromTimeString(hour.from, '', false)} -
                  {getTimeFormatFromTimeString(hour.to, '', false)}
                </Typography>
              ))
            }
          </Box>
        ))
      }
    </>);
  }, [otherProps.opening]);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  if (!otherProps.show_footer) {
    return <></>;
  }


  return (<>
    <section className="w3l-footer-29-main">
      <div className="footer-29 py-3">
        <div className="container py-lg-4">
          <div className="row footer-top-29">
            <div className="footer-list-29 col-lg-4">
              {/*<h6 className="footer-title-29">About Company</h6>*/}
              <h6 className="footer-title-29">{setting.site_title}</h6>
              <img src={`${SETTING_FILE_PATH + setting.site_logo_large}`} height="70" />
              <p className="footer-about">{setting.about_description?.substring(0, 235)}</p>

            </div>
            <div className="col-lg-3 col-md-6 col-sm-8 footer-list-29 footer-1 mt-lg-0 mt-5">
              <h6 className="footer-title-29">Contact Us</h6>
              <ul>
                <li>
                  <p className="footer-address"><span className="fa fa-map-marker" /> {setting.address}</p>
                </li>
                <li><a className="footer-phone" href={`tel:${setting.country_code}-${setting.phone}`}><span className="fa fa-phone" /> ({setting.country_code})-{setting.phone}</a></li>
                <li><a  href={`mailto: ${setting.display_email}`} className="footer-email"><span className="fa fa-envelope-open-o" /> {setting.display_email}</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-4 footer-list-29 footer-2 mt-lg-0 mt-5">
              <ul>
                <h6 className="footer-title-29">Useful Links</h6>
                <li><Link to={routes.about}>About Us</Link></li>
                <li><Link to={routes.privacyPolicy}>Privacy Policy</Link></li>
                <li><Link to={routes.termsConditions}>Terms & Conditions</Link></li>
                <li><Link to={routes.allergyInformation}>Allergy Information</Link></li>
                <li><Link to={routes.contact}>Contact Us</Link></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-5">
              <h6 className="footer-title-29">Opening Times</h6>
              <div className="footer-post mb-4">
                {
                  footerTiming
                }
              </div>
            </div>
            <div className="col-lg-12 footer-list-29 footer-4 mt-3">
              <div className="column1 align-self">
                <h6 className="footer-title-29  mb-1">We're Social Like You</h6>
                <p>Follow and Like us on our social media</p>
              </div>
              <div className="main-social-footer-29 mt-3 text-center">
                <a href={setting.facebook_url} target="_blank" className="facebook"><span className="fa fa-facebook" /></a>
                <a href={setting.trip_advisor} target="_blank" className="twitter"><span className="fa fa-tripadvisor" /></a>
                <a href={setting.instagram_url} target="_blank" className="instagram"><span className="fa fa-instagram" /></a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="w3l-footer-29-main w3l-copyright">
      <div className="container">
        <div className="bottom-copies">
          <p className="copy-footer-29 text-center">
            <Copyright />
          </p>
        </div>
      </div>

      <Hidden mdDown>
        <button onClick={() => topFunction()} id="movetop" title="Go to top">&#10548;</button>
      </Hidden>


      <Hidden mdUp>
        <BottomNav />
      </Hidden>


    </section>
  </>);
}

const mapStateToProps = state => ({

  show_footer: state.layout.show_footer,
  opening: state.opening.data,
  isLogin: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(Footer);
