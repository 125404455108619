import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from '@material-ui/pickers';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import TimePicker from '../util/TimePicker/TimePicker';
import TextField from '@material-ui/core/TextField';
import { cartDeliveryDateUpdate, cartDeliveryTimeUpdate, cartUpdateASAPTime } from '../../store/cart/cartActions';
import { connect } from 'react-redux';
import * as dateFns from 'date-fns';
import { applyTimeZone, getCurrentTime, getDateFormat, getTimeFormat, getTimeFormatFromTimeString, getValidOpeningStatus } from '../../util/util';
import useNotify from '../../hooks/useNotify';
import { clearUnavailableOrderWithTime, updateUnavailableOrderWithTime } from '../../store/opening/openingActions';

function DateTime({ ...otherProps }) {

  const [selectedDate, setSelectedDate] = useState(() => {
    return otherProps.deliveryDate.length ? applyTimeZone(new Date(otherProps.deliveryDate)) : applyTimeZone(new Date());
  });

  const [timePickerOpen, setTimePickerOpen] = useState(false);

  const [notify] = useNotify();

  const [availableTodayDelivery, setAvailableTodayDelivery] = useState([]);

  const [orderTypeUnavailable, setOrderTypeUnavailable] = useState("");


  const selectedDaysHours = useMemo(() => {
    // sync updated time & date to store
    otherProps.setDeliveryDate(dateFns.format(selectedDate, getDateFormat()));

    const selectedDayName = dateFns.format(selectedDate, 'EEE').toLowerCase();

    // set selected days hours
    const selectedDay = otherProps.opening.find(itm => itm.day === selectedDayName);

    const hours = selectedDay ? selectedDay.hours : [{ from: '00:00:00', to: '23:59:59' }];


    return hours;

  }, [selectedDate, otherProps.opening]);

  const [selectedTime, setSelectedTime] = useState(() => {
    const date = otherProps.deliveryDate.length ? applyTimeZone(new Date(otherProps.deliveryDate)) : null;

    // if date time available then set that
    if (otherProps.deliveryTime.length) {
      return new Date(`${date.toDateString()} ${otherProps.deliveryTime}`);
    }

    return false;
  });

  useEffect(() => {

    otherProps.clearUnavailableOrderWithTime();
    otherProps.setDeliveryIsASAPTime(false);


    const time = otherProps.todayTiming.hours?.map(val => {

      const [toHour, toMinute] = val.to.split(':');

      const [fromHour, fromMinute] = val.to.split(':');

      const toTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMinute), toHour);

      const fromTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMinute), fromHour);

      const currentDate = applyTimeZone(new Date());

      if (currentDate >= fromTime || currentDate <= toTime) {

        const isDelivery = val.enabled_delivery;
        const isCollection = val.enabled_collection;

        if (!isDelivery && otherProps.orderType === "Delivery") {
          setOrderTypeUnavailable("Delivery");
        } else if (!isCollection && otherProps.orderType === "Collection") {
          setOrderTypeUnavailable("Collection");
        }
      }

    });

    if (otherProps.orderType === "Delivery") {
      const availableDelivery = otherProps.todayTiming.hours?.filter(val => val.enabled_delivery === true);
      setAvailableTodayDelivery(availableDelivery);
    } else {
      const availableCollection = otherProps.todayTiming.hours.filter(val => val.enabled_collection === true);
      setAvailableTodayDelivery(availableCollection);
    }



  }, [otherProps.orderType])

  useEffect(() => {
    if (selectedTime === false) return;

    otherProps.updateUnavailableOrderWithTime("");

    otherProps.todayTiming.hours.map(val => {


      const [toHour, toMinute] = val.to.split(':');

      const [fromHour, fromMinute] = val.from.split(':');

      const toTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMinute), toHour);

      const fromTime = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), fromMinute), fromHour);


      if (selectedTime >= fromTime && selectedTime <= toTime) {

    
        const isDelivery = val.enabled_delivery;
        const isCollection = val.enabled_collection;

        if (!isDelivery && otherProps.orderType === "Delivery") {
          notify.warning("Delivery is not available at this time.");
          otherProps.updateUnavailableOrderWithTime(otherProps.orderType);

        } else if (!isCollection && otherProps.orderType === "Collection") {
          notify.warning("Collection is not available at this time.");
          otherProps.updateUnavailableOrderWithTime(otherProps.orderType);
        }
      }

    });
    otherProps.setDeliveryTime(dateFns.format(selectedTime, getTimeFormat()));
  }, [selectedTime]);

  const openingDays = useMemo(() => {
    return otherProps.opening.map(itm => itm.day);
  }, [otherProps.opening]);

  const dayDisableHandler = day => {

    // enable all days if opening days not set
    if (openingDays.length === 0) {
      return false;
    }

    const dayName = dateFns.format(day, 'EEE').toLowerCase();

    // only enable days that have timing set
    return !openingDays.includes(dayName);
  };

  const getSelectedTime = () => {
    if (selectedTime === false) return '';
    let time = dateFns.format(selectedTime, getTimeFormat())
    return time;
  };


  const isRestaurantOpen = useMemo(() => {
    return getValidOpeningStatus(otherProps.todayOpening.hours);
  }, [otherProps.todayOpening.hours]);

  useEffect(() => {
    if (otherProps.deliveryTime.length) return;

    if (orderTypeUnavailable === "") {
    otherProps.setDeliveryIsASAPTime(isRestaurantOpen);

    } else {
      otherProps.setDeliveryIsASAPTime(false);
    }
  }, [isRestaurantOpen, orderTypeUnavailable]);


  return (<>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <Grid item xs={12} md={6}>

          <form noValidate>
            <DatePicker
              disablePast
              value={selectedDate}
              inputVariant="outlined"
              onChange={setSelectedDate}
              shouldDisableDate={dayDisableHandler}
              type="text"
              fullWidth
              id="date"
              label="Select Date"
              name="date"
              autoFocus
              format={getDateFormat()}
            />

            {
              isRestaurantOpen && orderTypeUnavailable === "" &&
              <FormControlLabel
                className="mt-4 mb-0"
                label={
                  otherProps.cart.delivery.isAsapTime
                    ? `${otherProps.cart.order.type === 'Delivery' ? 'Deliver' : 'Collect'} ASAP`
                    : `${otherProps.cart.order.type} on specified time`
                }
                control={
                  <Switch
                    color="primary"
                    checked={otherProps.cart.delivery.isAsapTime}
                    onChange={() => otherProps.setDeliveryIsASAPTime(!otherProps.cart.delivery.isAsapTime)}
                  />
                }
              />
            }

            {
              orderTypeUnavailable !== "" &&
              <Typography
                variant="body1"
                style={{
                  marginLeft: 5,
                  marginTop: 10,
                }}
                color={'primary'}
              >
                {`${orderTypeUnavailable} order available in the following times:`}

              </Typography>
            }

            {
              orderTypeUnavailable !== "" &&
              availableTodayDelivery.map(val => {
                return (<>
                  <Typography
                    kay={`hr${val.id}`}
                    variant="body1"
                    style={{
                      marginLeft: 15
                    }}
                    color={'secondary'}
                  >
                    {getTimeFormatFromTimeString(val.from, '', false)} to {getTimeFormatFromTimeString(val.to, '', false)}
                  </Typography>

                </>)

              })
            }

            <TextField
              hidden={otherProps.cart.delivery.isAsapTime}
              type="text"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="time"
              label="Select Time"
              id="time"
              autoComplete='off'
              value={getSelectedTime()}
              onFocus={() => setTimePickerOpen(true)}
            />
          </form>

        </Grid>

        <TimePicker
          open={timePickerOpen}
          date={selectedDate}
          hours={selectedDaysHours}
          defaultValue={selectedTime}
          closeHandler={() => setTimePickerOpen(false)}
          changeHandler={(time) => setSelectedTime(time)}
          minuteStep={5}
        />
      </Grid>

    </MuiPickersUtilsProvider>
  </>);
}

const mapStateToProps = state => ({
  deliveryDate: state.cart.delivery.date,
  deliveryTime: state.cart.delivery.time,
  opening: state.opening.data,
  todayTiming: state.opening.todaysTiming,
  cart: state.cart,
  todayOpening: state.opening.todaysTiming,
  orderType: state.cart.order.type,
});

const mapDispatchToProps = dispatch => ({
  setDeliveryDate: (date) => dispatch(cartDeliveryDateUpdate(date)),
  setDeliveryTime: (time) => dispatch(cartDeliveryTimeUpdate(time)),
  setDeliveryIsASAPTime: (isASAP) => dispatch(cartUpdateASAPTime(isASAP)),
  updateUnavailableOrderWithTime: (type) => dispatch(updateUnavailableOrderWithTime(type)),
  clearUnavailableOrderWithTime: () => dispatch(clearUnavailableOrderWithTime()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTime);
