import { CircularProgress, Grid, List, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import PageHeader from '../../../components/pageHeader/pageHeader';
import UserProfileComponent from '../../../components/user/userProfileComponent';
import { Link } from 'react-router-dom';
import routes from "../../../util/routes";
import '../../../assets/css/custom.scss';
import { urlRedirectSet } from '../../../store/url/urlActions';
import { connect } from 'react-redux';
import SlideAnimation from '../../../components/util/Animations/SlideAnimation';


const useStyles = makeStyles((theme) => ({

  activeColor: {
    backgroundColor: "#F3F3F3",
  },
}));

function UserProfile({ ...otherProps }) {

  useEffect(() => {

    if (!otherProps.user.isLoggedIn) {

      otherProps.setUrlRedirect(otherProps.location.pathname, routes.login);

      // redirect
      otherProps.history.push(routes.login);

      return;
    }
  }, [otherProps.user]);

  const classes = useStyles();

  return (<>

    <SlideAnimation in={true}>

    <PageHeader pageName="My Profile" />

    <Grid container>

      <Grid item xs={12} md={3} margin="2">
        <Link to={routes.orderHistory}>
          <Paper>
              <List component="nav" align="center" className="profile-padding profile-margin">
              Today's Order
          </List>
          </Paper>
        </Link>

        <Link to={routes.userProfile}>
          <Paper className={classes.activeColor}>
              <List component="nav" align="center" className="profile-padding">
              My Profile
          </List>
          </Paper>
        </Link>

      </Grid>

      <Grid item xs={12} md={9}>
        <UserProfileComponent />
      </Grid>

    </Grid>
    </SlideAnimation>
  </>);
}

const mapStateToProps = state => ({
  user: state.user,
  cart: state.cart,
});

const mapDispatchToProps = dispatch => ({
  setUrlRedirect: (from, to) => dispatch(urlRedirectSet(from, to)),

})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
