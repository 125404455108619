import React, { useEffect, useReducer, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { loginStyles } from './login.style';
import { connect } from 'react-redux';
import { footerShowHide, headerShowHide } from '../../store/layout/layoutActions';
import { login, registrationDirect } from '../../store/user/userActions';
import _ from 'lodash';
import routes from '../../util/routes';
import Copyright from '../../components/util/copyright';
import { formSingleValidator, formValidator, validationRules } from '../../util/formValidation';
import { Alert } from '@material-ui/lab';
import useNotify from "../../hooks/useNotify";
import useRedirectIfAuthenticated from '../../hooks/useRedirectIfAuthenticated';
import useRedirectToIntended from '../../hooks/useRedirectToIntended';
import { CircularProgress } from '@material-ui/core';

const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    name: false,
    email: false,
    password: false,
    password_confirmation: false,
    phone: false
  }
};

function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) ? payload : 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function Register(props) {

  // redirect users if they already authenticated
  useRedirectIfAuthenticated();

  // redirect to the page they wanted to go before interception
  useRedirectToIntended();

  const classes = loginStyles();

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  const [isloading, setIsLoading] = useState(false);

  // hide/show header footer
  useEffect(() => {

    // hiding header footer
    toggleHeaderFooter(false);

    // showing header footer
    return () => toggleHeaderFooter(true);

  }, []);

  const toggleHeaderFooter = show => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }

  const [notify] = useNotify();

  // handle form inputs
  const [credentials, setCredentials] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phone: ''
  });

  const validationSchema = {
    name: [
      validationRules.required(),
      validationRules.string(),
      validationRules.min([3]),
      validationRules.max([30])
    ],
    email: [
      validationRules.required(),
      validationRules.email()
    ],
    password: [
      validationRules.required(),
      validationRules.min([8]),
      validationRules.max([30]),
      validationRules.alphaNumeric()
    ],
    password_confirmation: [
      validationRules.matchWithField(['password', credentials.password])
    ],
    phone: [
      validationRules.required(),
      validationRules.min([7])
    ]
  };

  const changeHandler = e => {
    const name = e.target.name;
    const value = e.target.value;

    setCredentials({
      ...credentials,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);

      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors,
      });
    }, 500)();
  };


  const submitHandler = async (e) => {
    
    setIsLoading(true);
    
    e.preventDefault();

    // all field validation before submit
    const { error, data } = await formValidator(credentials, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      setIsLoading(false);
      return;
    }

    try {

      errorDispatch({
        type: errorTypes.SUBMIT
      });

      const registration = await props.registration(
        credentials.name,
        credentials.email,
        credentials.password,
        credentials.password_confirmation,
        credentials.phone
      );

     

      if (registration.status === 200) {
        setIsLoading(false);
        errorDispatch({
          type: errorTypes.SUBMIT_SUCCESS,
          payload: registration.message
        });
        notify.success("Sign up complete, you are successfully logged in.");
        props.login(credentials.email, credentials.password);
      } else {
        setIsLoading(false);

        errorDispatch({
          type: errorTypes.SUBMIT_FAIL,
          payload: registration.message
        });
        errorDispatch({
          type: errorTypes.FIELD_ERROR,
          payload:{email:registration.errors.email[0]},
        });
      }

    } catch (e) {
      setIsLoading(false);
      const err = e.response?.data;

      if (!err) {
        errorDispatch({
          type: errorTypes.SUBMIT_FAIL,
          payload: err?.message
        });
      }
    }

  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>

          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>

          {
            errorState.error &&
            <Alert severity="error" variant="standard">{errorState.message}</Alert>
          }
          {
            errorState.success &&
            <Alert severity="success" variant="standard">{errorState.message}</Alert>
          }


          <form className={classes.form} noValidate onSubmit={submitHandler}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Full Name"
              name="name"
              autoFocus
              error={!!errorState.fields.name}
              helperText={errorState.fields.name}
              value={credentials.name}
              onChange={changeHandler}
            />
            <TextField
              type="email"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              error={!!errorState.fields.email}
              helperText={errorState.fields.email}
              value={credentials.email}
              onChange={changeHandler}
            />
            <TextField
              type="password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              error={!!errorState.fields.password}
              helperText={errorState.fields.password}
              value={credentials.password}
              onChange={changeHandler}
            />
            <TextField
              type="password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password_confirmation"
              label="Confirm Password"
              id="password_confirmation"
              error={!!errorState.fields.password_confirmation}
              helperText={errorState.fields.password_confirmation}
              value={credentials.password_confirmation}
              onChange={changeHandler}
            />
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone no."
              name="phone"
              error={!!errorState.fields.phone}
              helperText={errorState.fields.phone}
              value={credentials.phone}
              onChange={changeHandler}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
             
              Sign Up
              {
                isloading &&
                <CircularProgress color="#fff" size={25} style={{ marginLeft: 7 }} />
              }
            </Button>

            <Grid container>
              <Grid item xs>
                <RouterLink to={routes.home}>
                  Back To Home
                </RouterLink>
              </Grid>
              <Grid item>
                <RouterLink to={routes.login}>
                  Back to Login
                </RouterLink>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data
});

const mapDispatchToProps = dispatch => ({
  showHideHeader: shouldShow => dispatch(headerShowHide(shouldShow)),
  showHideFooter: shouldShow => dispatch(footerShowHide(shouldShow)),
  login: (email, password, remember) => dispatch(login(email, password, remember)),
  registration: (name, email, password, confirmPassword, phone) => dispatch(registrationDirect(name, email, password, confirmPassword, phone))
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
